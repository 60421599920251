<template>
  <div class="modify-phone-container">
    <div
      v-if="modifyStep == 1"
      class="modify-phone-sure col-center"
    >
      <img src="../assets/icon/phone.png" />
      <p class="modify-phone-title">当前绑定手机号码</p>
      <p class="modify-phone-num">{{  phoneNumHide  }}</p>
      <van-button
        round
        block
        type="info"
        @click="modifyPhone"
      >更换手机号</van-button>
    </div>
    <div
      v-if="modifyStep == 2"
      class="certification-main"
    >
      <p class="certification-main-title">验证已绑定手机号码</p>
      <van-form @submit="onSubmit">
        <p class="modify-phone-num">{{  phoneNumHide  }}</p>
        <div
          v-if="isCaptcha1"
          @click="getSms"
          style="margin-left: 5%;margin-bottom: 16px;"
          class="right-slider-black-container"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider1"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>
        <div class="cer-sms-container">
          <van-field
            autocomplete="off"
            v-model="sms"
            ref="sms"
            class="login-sms"
            type="digit"
            maxlength="6"
            name="validatorSms"
            label="短信验证码"
            :rules="[{validator:validatorSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="disabled"
            @click="getSms"
          >
            {{ text }}
          </button>
        </div>
        <div
          style="margin: 1.25rem 1.125rem 1rem 1.375rem;"
          class="certification-tip-list"
        >
          <p class="certification-tip">说明：</p>
          <p class="certification-tip">1、请确认新手机号码未在平台上注册过，否则无法换绑。</p>
          <p class="certification-tip">2、换绑后需重新进行实名认证。</p>
          <p class="certification-tip">3、仅支持换绑中国大陆地区手机号码。</p>
        </div>
        <div style="margin: 8.75rem 1.125rem 1rem 1.375rem;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >下 一 步</van-button>
        </div>
      </van-form>

    </div>
    <div
      v-if="modifyStep == 3"
      class="certification-main"
    >
      <p class="certification-main-title">当前绑定手机号码</p>
      <p class="modify-phone-num">{{  phoneNumHide  }}</p>
      <van-form @submit="onSubmitModify">
        <van-field
          autocomplete="off"
          v-model="newPhone"
          class="login-phone"
          maxlength="11"
          ref="newPhoneVal"
          type="tel"
          name="validatorPhone"
          label="新手机号码"
          :rules="[{ validator:validatorPhone, message: '请输入正确的手机号码' }]"
        />
        <div
          v-if="isCaptcha2"
          @click="getNewPhoneSms"
          style="margin-left: 5%;margin-bottom: 16px;margin-top: 16px;"
          class="right-slider-black-container"
        >
          <p class="right-slider-p">滑块验证</p>
          <div
            id="login-main-right-slider2"
            class="login-main-right-slider"
          >
            <!-- 滑块验证 -->
          </div>
        </div>
        <div class="cer-sms-container">
          <van-field
            autocomplete="off"
            v-model="newPhoneSms"
            class="login-sms"
            type="digit"
            ref="newPhoneSms"
            maxlength="6"
            name="validatorSms"
            label="短信验证码"
            :rules="[{validator:validatornewPhoneSms, message: '请输入正确的短信验证码' }]"
          />
          <button
            type="button"
            class="sms-button"
            :disabled="newDisabled"
            @click="getNewPhoneSms"
          >
            {{ newPhoneText }}
          </button>
        </div>
        <div
          style="margin: 2.5rem 1.125rem 1rem 1.375rem;"
          class="certification-tip-list"
        >
          <p class="certification-tip">说明：</p>
          <p class="certification-tip">1、请确认新手机号码未在平台上注册过，否则无法换绑。</p>
          <p class="certification-tip">2、换绑后需重新进行实名认证。</p>
          <p class="certification-tip">3、仅支持换绑中国大陆地区手机号码。</p>
        </div>
        <div style="margin: 4.5rem 1.125rem 1rem 1.375rem;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >提 交</van-button>
        </div>
      </van-form>

    </div>
  </div>
</template>
<script>
import { create } from '../lib/captcha.js'
import { captchaAppKey } from '../lib/index.js'
import Vue from 'vue';
import md5 from 'js-md5';
import Cookies from 'js-cookie'
import api from '../api/index-client'
import { Form,Field,Button } from 'vant';
const config = require('../config')

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
export default {
  data () {
    return {
      isCaptcha1: false,
      isCaptcha2: false,
      modifyStep: 1,
      phoneNum: '',
      phoneNumHide: '',
      username: '',
      idCard: '',
      time: null,
      newPhoneTime: null,
      sms: '',
      newPhone: '',
      newPhoneSms: '',
      disabled: false,
      newDisabled: false,
      text: '获取验证码',
      newPhoneText: '获取验证码'
    }
  },
  watch: {
    newPhone () {
      if (this.newPhone.length >= 11 || !/^[0-9]*$/.test(this.newPhone)) {
        this.$refs.newPhoneVal.validate()
      }
    },
    newPhoneSms () {
      if (this.newPhoneSms.length >= 6 || !/^[0-9]*$/.test(this.newPhoneSms)) {
        this.$refs.newPhoneSms.validate()
      }
    },
    sms () {
      if (this.sms.length >= 6 || !/^[0-9]*$/.test(this.sms)) {
        this.$refs.sms.validate()
      }
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getMyInfo() // 获取个人资料
  },
  methods: {
    validatorPhone (val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
    },
    modifyPhone () {
      this.modifyStep = 2
    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.phoneNum = result.data.data.phone
            this.phoneNumHide = String(this.phoneNum).substring(0,3) + '****' + String(this.phoneNum).substring(String(this.phoneNum).length - 4,)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    validatornewPhoneSms (val) {
      return /^\d{6}$/.test(val);
    },
    validatorSms (val) {
      return /^\d{6}$/.test(val);
    },
    getNewPhoneSms () {
      /**
        * 创建滑块验证模块，并重置登录所需参数
        */
      this.$refs.newPhoneVal.validate().then(result => {
        if (!result) {
          this.isCaptcha2 = true
          this.$nextTick(() => {
            this.captcha = create('login-main-right-slider2',data => {
              var timestamp = new Date().getTime()
              var nonce = md5(String(timestamp))
              var signStr = 'appId=' + config.appId + '&mobile=' + this.newPhone + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
              var sign = md5(signStr)
              api
                .post('afs/sms',{ phone: this.newPhone,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
                .then(result => {
                  if (result.data.success) {
                    this.newPhoneTime = 60;
                    this.newPhoneTimer();
                    this.$toast({
                      message: '获取成功',
                      icon: require('../assets/icon/toast-success.png'),
                    });
                  } else {
                    this.$toast({
                      message: result.data.msg,
                      icon: require('../assets/icon/toast-error.png'),
                    });
                  }
                  this.isCaptcha2 = false
                })
            })
            this.captcha.reset()
            this.captcha.show()
          })

        }
      })
    },
    getSms () {
      this.isCaptcha1 = true
      this.$nextTick(() => {
        this.captcha = create('login-main-right-slider1',data => {
          var timestamp = new Date().getTime()
          var nonce = md5(String(timestamp))
          var signStr = 'appId=' + config.appId + '&mobile=' + this.phoneNum + '&nonce=' + nonce + '&timestamp=' + timestamp + '&url=' + config.server + '/api/sms&' + config.appSecret
          var sign = md5(signStr)
          api
            .post('afs/sms',{ phone: this.phoneNum,sessionId: data.sessionId,signature: data.sign,token: data.token,scene: data.scene,appkey: captchaAppKey },{},{ 'X-Requested-With': 'XMLHttpRequest','Access-Control-Allow-Origin': '*','Access-Control-Allow-Method': 'POST,GET,DELETE,OPTIONS','Authorization': "Bearer " + Cookies.get('Login'),"appId": config.appId,"timestamp": timestamp,"nonce": nonce,"sign": sign,"url": config.server + '/api/sms' })
            .then(result => {
              if (result.data.success) {
                this.time = 60;
                this.timer();
                this.$toast({
                  message: '获取成功',
                  icon: require('../assets/icon/toast-success.png'),
                });
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
              this.isCaptcha1 = false
            })
        })
        this.captcha.reset()
        this.captcha.show()
      })
    },
    //发送手机验证码倒计时
    timer () {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.text = this.time + "秒";
        setTimeout(this.timer,1000);
      } else {
        this.time = 0;
        this.text = "获取验证码";
        this.disabled = false;
      }
    },
    //发送手机验证码倒计时
    newPhoneTimer () {
      if (this.newPhoneTime > 0) {
        this.newDisabled = true;
        this.newPhoneTime--;
        this.newPhoneText = this.newPhoneTime + "秒";
        setTimeout(this.newPhoneTimer,1000);
      } else {
        this.newPhoneTime = 0;
        this.newPhoneText = "获取验证码";
        this.newDisabled = false;
      }
    },
    onSubmit (values) {
      api
        .post('verify',{ "mobile": this.phoneNum,"code": this.sms,from: 'h5',deviceNo: 'h5' })
        .then(result => {

          if (result.data.success && result.data.data) {
            this.$toast({
              message: '操作成功',
              icon: require('../assets/icon/toast-success.png'),
            });
            this.modifyStep = 3
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    onSubmitModify (values) {
      api
        .post('user/resetPhone ',{ "phone": this.newPhone,"code": this.newPhoneSms })
        .then(result => {

          if (result.data.success && result.data.data) {
            this.$toast({
              message: '操作成功',
              icon: require('../assets/icon/toast-success.png'),
            });
            this.$router.replace('/myDetail')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });

          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.modify-phone-container {
  .certification-tip {
    font-size: 0.75rem;
    font-family: lantinghei;
    font-weight: normal;
    color: #cccccc;
    line-height: 1.375rem;
    letter-spacing: 0.0625rem;
  }
  /deep/.van-field__control {
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff !important;
    line-height: 1.1875rem;
  }
  .certification-main {
    padding-top: 1.3125rem;

    .van-form {
      .van-cell::after {
        display: none;
      }

      .van-cell {
        background: none;
        padding: 1rem 0.8125rem;

        .van-cell__title,
        .van-cell__value {
          font-size: 0.875rem;
          font-family: lantingheiweight;
          font-weight: normal;
          color: #b3b3b3 !important;
          line-height: 1.1875rem;
        }

        .van-field__error-message {
          position: absolute;
          top: 1rem;
        }
      }

      .van-button {
        height: 2.875rem;
        position: relative;
        background-image: url('../assets/icon/confirm-button.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: 100% auto;
        background-color: transparent;
        font-size: 1rem;
        font-family: lantingheiweight;
        font-weight: normal;
        color: #ffffff;
        line-height: 1.375rem;
        border-radius: 0;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .modify-phone-num {
    text-align: center;
    margin: 1.0625rem 0 2.375rem 0;
    font-size: 1.5rem;
    font-weight: 800;
    color: #ffffff;
    line-height: 2.125rem;
    letter-spacing: 0.0625rem;
  }
  .certification-main {
    padding-top: 1.3125rem;

    .certification-main-title {
      text-align: center;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #cccccc;
      line-height: 19px;
      margin-bottom: 1.0625rem;
    }
  }
  .modify-phone-sure {
    height: calc(100vh - 4.25rem);
    width: calc(100vw - 0.9375rem);
    z-index: 20;
    margin: 0.5rem 0.5625rem 0.625rem 0.375rem;
    background: #1c1c1c;
    img {
      width: 5.625rem;
      height: auto;
    }
    .modify-phone-title {
      margin-top: 1.75rem;
      font-size: 0.875rem;
      font-family: lantinghei;
      font-weight: normal;
      color: #cccccc;
      line-height: 1.1875rem;
    }
    button {
      width: 18.75rem;
      height: 2.875rem;
      background: #4a4a4a;
      font-size: 1rem;
      font-family: lantinghei;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      letter-spacing: 0.125rem;
      border-radius: 0;
      border: none;
      outline: none;
    }
  }
  .login-phone {
    margin-bottom: 1rem;
  }

  .login-phone,
  .cer-sms-container {
    width: 90%;
    margin-left: 5%;
    height: 3.25rem;
    background: #313131 !important;
    border-radius: 0.25rem;
    position: relative;
    .van-cell__value {
      input {
        width: 6.25rem;
      }
    }
    button {
      position: absolute;
      bottom: 1rem;
      right: 0.625rem;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875rem;
      font-family: lantinghei;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.1875rem;
    }
  }
}
</style>